<script setup lang="ts">
import type { HomeJackpots, Game } from "~/types";

import type { KeenSliderInstance, KeenSliderOptions } from "keen-slider";

const { t } = useT();
const router = useRouter();

const DEFAULT_INCREMENT_STEP = 0.15;
const DEFAULT_INCREMENT_INTERVAL = 550;

const props = defineProps<{
	gameMode?: string;
	jackpots?: HomeJackpots;
	games: Game[];
}>();

const prepareImgUrl = useImage();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;
const { handleToggleToFavoriteClick } = useAddToFavorite();
const { data: appInitData } = useAppInitData();

const intervalId = ref<ReturnType<typeof setInterval>>();
const amount = ref(0);
const arraybleAmount = ref<string[]>(["0"]);

const isSweepStakes = computed(() => props.gameMode === "SweepStakes");
const totalWinAmount = computed(() => {
	const values = props.jackpots?.[isSweepStakes.value ? "USD" : "TRN"]?.values ?? [];
	return values.reduce((acc, item) => acc + (item.amount ?? 0), 0) * 100;
});
const sliderJackpotRef = ref();
const sliderActiveSlide = ref(0);
const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	breakpoints: {
		"(max-width: 767px)": {
			slides: {
				perView: "auto",
				spacing: 8
			}
		}
	},
	slides: { perView: "auto", spacing: 8 },
	slideChanged(slider: KeenSliderInstance) {
		sliderActiveSlide.value = slider.track.details.abs;
	}
};

const incrementValue = () => {
	amount.value += DEFAULT_INCREMENT_STEP;
	if (isSweepStakes.value) {
		arraybleAmount.value = numberFormat(amount.value, { minimumFractionDigits: 2 }).replace(/\.\d*/, "").split("");
		return;
	}
	arraybleAmount.value = numberFormat(amount.value * 100).split("");
};

const goToJackpotGames = () => {
	router.push("/issues/jackpot/");
};

const bgDesktop = `url(${prepareImgUrl(`/nuxt-img/jackpot/jackpot-bg.jpg`, {
	format: "avif",
	width: 974
})})`;

const bgMobile = `url(${prepareImgUrl(`/nuxt-img/jackpot/jackpot-bg-mob.jpg`, {
	format: "avif"
})})`;

watch(totalWinAmount, () => {
	amount.value = totalWinAmount.value;
});

onMounted(() => {
	amount.value = totalWinAmount.value;
	intervalId.value = setInterval(incrementValue, DEFAULT_INCREMENT_INTERVAL);
});
onUnmounted(() => clearInterval(intervalId.value));
</script>

<template>
	<div class="jackpot-wrapper">
		<div class="jackpot">
			<AAnimationPrizeAndStars />
			<AText class="text" variant="taipei" :modifiers="['semibold']">
				{{ t("Total Jackpot Amount") }}
			</AText>

			<div class="values">
				<AText
					variant="tianshui"
					:modifiers="['bold', 'center']"
					class="amount"
					:class="{ 'amount--sweep': isSweepStakes }"
					as="div"
				>
					<TransitionGroup name="counter">
						<span
							v-for="(item, index) in arraybleAmount"
							:key="item + index"
							:class="['amount-item', { 'is-separator': isNaN(parseInt(item)) }]"
						>
							{{ item }}
						</span>
					</TransitionGroup>
				</AText>
			</div>
			<div class="content">
				<ASlider ref="sliderJackpotRef" :options="sliderOptions">
					<div v-for="(game, i) in games" :key="i" class="swiper-slide keen-slider__slide">
						<MGame
							:key="game.id"
							:is-guest="!!appInitData?.isGuest"
							:is-only-for-app="!!game.isOnlyForApp"
							:is-favorite="!!game.isFavorite"
							:is-new="!!game.isNew"
							:image="`${baseImageUrl}${game.logo || game.img}`"
							:image-pattern="imagePattern"
							:game-id="game?.id || 0"
							:grade="game?.grade || ''"
							:game="game"
							:skeleton="'skeleton' in game"
							@toggle-favorite="handleToggleToFavoriteClick(game)"
						/>
					</div>
				</ASlider>
				<div v-if="games.length > 1" class="pagination">
					<div
						v-for="(_slide, idx) in games.length"
						:key="idx"
						:class="['pagination-item', { 'is-active': sliderActiveSlide === idx }]"
						@click="sliderJackpotRef.slider?.moveToIdx(idx)"
					/>
				</div>
			</div>
			<div class="action">
				<AAnimationCustomByScroll animationClass="blink">
					<AButton variant="primary" class="btn" size="xl" @click="goToJackpotGames">
						<AText variant="turin" :modifiers="['medium']" class="text-tlalnepantla">
							{{ t("Play now") }}
						</AText>
						<ASvg name="24/play" class="icon" />
					</AButton>
				</AAnimationCustomByScroll>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
:deep {
	.keen-slider {
		width: 496px !important;
		max-width: 100%;
		z-index: 5;
	}

	.swiper-slide {
		flex-shrink: 0;

		.game-wrapper {
			width: 160px;
		}
	}
}
.pagination {
	display: flex;
	justify-content: center;
	gap: 4px;
	margin-top: 8px;
	padding: 0 16px;

	&-item {
		width: 16px;
		height: 4px;
		border-radius: 12px;
		background: var(--celaya);
		cursor: pointer;

		&:nth-last-child(1),
		&:nth-last-child(2) {
			display: none;
		}

		&:nth-last-child(2) {
			@media (max-width: 580px) {
				display: block;
			}
		}

		&.is-active {
			width: 24px;
			background: var(--сirebon);
		}
	}
}
.counter {
	&-enter-from,
	&-leave-to {
		opacity: 0;
	}

	&-enter-from {
		transform: translateY(40px);
	}

	&-leave-active {
		position: absolute;
	}

	&-leave-to {
		transform: translateY(-40px);
	}
}

.jackpot-wrapper {
	flex-grow: 2;
	height: 458px;
	padding: 2px;
	border-radius: 24px;
	background: var(--garoua);

	@include media-breakpoint-down(md) {
		height: auto;
	}

	@include media-breakpoint-down(sm) {
		max-width: 380px;
		margin: 0 auto;
	}
}

.text,
.values {
	position: relative;
	z-index: 2;
}

.jackpot {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 24px;
	background-image: v-bind(bgDesktop);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center right;
	border-radius: 24px;
	position: relative;
	background-color: #1c141f;

	@include media-breakpoint-down(md) {
		overflow: hidden;
		@media (orientation: landscape) {
			height: 385px;
		}
	}

	@include media-breakpoint-down(sm) {
		align-items: center;
		background-image: v-bind(bgMobile);
		background-color: var(--carletonville);
		background-position: 50% 0;
		height: 671px;
		margin: 0 auto;
		background-size: contain;
		padding: 250px 16px 24px;
	}

	.content {
		max-width: 100%;
		margin-top: 25px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 1;
	}

	.amount {
		margin-top: 10px;
		overflow: hidden;
		line-height: 1;
		color: var(--cheremushki);

		&--sweep {
			color: var(--curico);
		}

		&-item:not(.is-separator) {
			transition: all 0.55s ease;
			display: inline-block;
			width: 45px;
			@include media-breakpoint-down(lg) {
				@media (orientation: landscape) {
					width: 28px;
				}
			}
			@include media-breakpoint-down(md) {
				width: 28px;
			}
		}

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				font-size: 32px;
			}
		}
	}
}
.action {
	display: flex;
	margin-top: auto;
	position: relative;
	user-select: none;

	@include media-breakpoint-down(md) {
		width: 100%;
	}

	:deep(.blink) {
		width: 100%;
	}
}

.btn {
	padding: 0 24px;
	@include media-breakpoint-down(md) {
		width: 100%;
	}
	:deep(svg) {
		font-size: 24px;
		margin-left: 8px;
	}
}
</style>
